import React from 'react';
import { AnimatePresence } from 'framer-motion';
import Loader from './src/components/loader';
import Cursor from './src/components/elements/cursor';

const PageWrapper = ({ element }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPageLoaded, setIsPageLoaded] = React.useState(false);

  React.useEffect(() => {
    // Simulate async data loading
    setTimeout(() => {
      setIsLoading(false);
    }, 2750);
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      setIsPageLoaded(true);
    }
  }, [isLoading]);

  const handleRouteChange = () => {
    document.body.classList.remove('disable-scroll');
  };

  React.useEffect(() => {
    window.addEventListener('routeChange', handleRouteChange);
    return () => {
      window.removeEventListener('routeChange', handleRouteChange);
    };
  }, []);

  return (
    <React.Fragment>
      <AnimatePresence mode="wait">
        {isLoading ? <Loader key="loader" /> : element}
        {isPageLoaded && <Cursor />}
      </AnimatePresence>
    </React.Fragment>
  );
};

export const wrapPageElement = ({ element }) => {
  return (
    <PageWrapper element={element} />
  );
};
