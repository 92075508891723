import React from 'react';
import { styled } from '@linaria/react';
import { motion } from 'framer-motion';
import LottiePlayer from 'react-lottie-player';

/**
 * Local Imports
 */
import animationData from './lottie/loader.json';

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--textColor);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

/**
 * Loader Component.
 *
 * @param {Object} props React props
 * @returns {React.ReactElement} Loader Component
 * @public
 */

function Loader() {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <LottiePlayer
        loop
        play
        animationData={animationData}
        speed={1.25}
        style={{ height: '100vw', width: '100vw' }}
      />
    </Container>
  );
}

export default Loader;
