import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { styled } from '@linaria/react';
import { device } from '../../../global/breakpoints';

const CursorContainer = styled.div`
  display: none;
  background-color: transparent;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.08, 0.5, 0.1, 1);
  transform: none;
  opacity: 0;

  @media only screen and ${device.tablet} {
    display: flex;
    background-color: #063dcc;
    border: 1px solid transparent;

    &.image-hover {
      mix-blend-mode: difference;
    }

    &.defaultHoverEffect {
      mix-blend-mode: difference;
    }

    &.anchor-link {
      background-color: transparent;
      height: 26px;
      width: 26px;
      border: 1px solid #063dcc;
      mix-blend-mode: unset;
    }

    &.awwwards {
      background-color: transparent !important;
      border: 1px solid var(--backgroundColor);
      height: 26px;
      width: 26px;
      transform: translate3d(0, 40px, 0);
    }

    &.footer {
      background-color: var(--backgroundColor) !important;
      height: 18px;
      width: 18px;
      transform: translate3d(0, 40px, 0);

      &.footer-anchor-link {
        background-color: transparent !important;
        border: 1px solid var(--backgroundColor);
        height: 26px;
        width: 26px;
        transform: translate3d(0, 40px, 0);
      }
    }

    &.footer-anchor-link-oguzhan-curavci {
      background-color: transparent !important;
      border: 1px solid var(--textColor);
      height: 26px;
      width: 26px;
      transform: translate3d(0, 40px, 0);
    }

    &.anchor-image-container {
      background-color: #063dcc;
      height: 122px;
      width: 122px;
      transform: translate3d(0, 40px, 0);
      overflow: hidden;
      mix-blend-mode: unset;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 54px;
        height: 42px;
        background-image: url('data:image/svg+xml,%3Csvg width="54" height="42" viewBox="0 0 54 42" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg clip-path="url(%23clip0_224_19744)"%3E%3Cpath d="M36.7279 0L54.0496 18.0662V23.9853L36.7279 42.0515H27.5956L44.5202 24.3971H0V17.6544H44.4706L27.546 0H36.6783H36.7279Z" fill="%23FFFEEE"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_224_19744"%3E%3Crect width="54" height="42" fill="white"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');
        background-size: contain;
        background-repeat: no-repeat;
        animation: arrowAnim 2.75s cubic-bezier(0.08, 0.75, 0.17, 1) forwards;
        transform-origin: center;
      }

      @keyframes arrowAnim {
        0% { transform: translate3d(-200%, -50%,0) scale(0); }
        30% { transform: translate3d(-50%, -50%,0) scale(1); }
        100% { transform: translate3d(-50%, -50%,0) scale(1); }
      }
    }
  }
`;
const Cursor = () => {
  const cursorRef = useRef(null);
  const isClient = typeof window !== 'undefined' ? window.location.pathname : null;

    useEffect(() => {
      const mouseMove = event => {
        if (cursorRef.current) {
          cursorRef.current.style.transform = `translate3d(${event.clientX - 16}px, ${event.clientY - 16}px, 0)`;
          cursorRef.current.style.opacity = "1";
        }
      };
    
      window.addEventListener('mousemove', mouseMove);
    
      return () => {
        window.removeEventListener('mousemove', mouseMove);
      };
    }, []);

  useEffect(() => {
    const cursorElement = document.getElementById('cursor');
    const hover = event => {
      const hoverClass = getHoverClass(event.currentTarget);
      if (cursorElement) {
        cursorElement.classList.add(hoverClass);
      }
    };

    const unhover = event => {
      const hoverClass = getHoverClass(event.currentTarget);
      if (cursorElement) {
        cursorElement.classList.remove(hoverClass);
      }
    };

    const links = document.querySelectorAll('a, footer > div, button, video, img, p, h1, svg, h2, [data-cursor="nav-item"]');
    links.forEach(link => {
      link.addEventListener('mouseover', hover);
      link.addEventListener('mouseout', unhover);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('mouseover', hover);
        link.removeEventListener('mouseout', unhover);
      });
    };
  }, [isClient]);

  const inOut = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    transition: {
      duration: 1,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  };

  function getHoverClass(link) {
    switch (link.dataset.cursor) {
      case 'anchor-link':
        return 'anchor-link';
      case 'anchor-image-container':
        return 'anchor-image-container';
      case 'footer':
        return 'footer';
      case 'footer-anchor-link':
        return 'footer-anchor-link';
      case 'footer-anchor-link-oguzhan-curavci':
        return 'footer-anchor-link-oguzhan-curavci';
      case 'image-hover':
        return 'image-hover';
      case 'nav-item':
        return 'anchor-link';
      case 'awwwards':
        return 'awwwards';
      default:
        return 'defaultHoverEffect'; // Fallback hover effect
    }
  }

  if (!isClient) return null; 

  return (
    <motion.div
      data-s="cursor"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={inOut}
      key={isClient}
    >
      <CursorContainer
        id="cursor"
        ref={cursorRef}
      ></CursorContainer>
    </motion.div>
  );
};

export default Cursor;
